const initialState = {
  dealer_members: "",
  admin_members: "",
  sr_members: "",
  rf_members: "",
  claim_count: "",
  stats_count: "",
  agreement_count: "",
  underwriter_count: "",
  plan_stats: [],
};

const dashboardReducers = (state = initialState, actions) => {
  switch (actions.type) {
    case "GET_DEALER_MEMBERS":
      return { ...state, dealer_members: actions.data };
    case "GET_ADMIN_MEMBERS":
      return { ...state, admin_members: actions.data };
    case "GET_SR_MEMBERS":
      return { ...state, sr_members: actions.data };
    case "GET_RF_MEMBERS":
      return { ...state, rf_members: actions.data };
    case "GET_CLAIM_COUNT":
      return { ...state, claim_count: actions.data };
    case "GET_STATS_COUNT":
      return { ...state, stats_count: actions.data };
    case "GET_AGREEMENT_COUNT":
      return { ...state, agreement_count: actions.data };
    case "GET_UNDERWRITER_COUNT":
      return { ...state, underwriter_count: actions.data };
    case "GET_PLAN_STATS":
      return { ...state, plan_stats: actions.data };
    default:
      return state;
  }
};

export default dashboardReducers;
